<template>
  <v-navigation-drawer
    width="250"
    fixed
    app
    floating
    :expand-on-hover="mini"
    :value="drawer"
  >
    <v-list-item class="pa-0">
      <v-list-item-content class="pa-0">
        <v-list-item-title class="title d-flex align-center mb-0">
          <div class="v-navigation-drawer-brand pa-5 d-flex align-center">
            <img src="@/assets/img/green.svg" class="w-70 ml-auto" />
          </div>

          <div
            class="drawer-toggler pa-5 cursor-pointer"
            :class="{ active: togglerActive }"
            @click="minifyDrawer"
          >
            <div class="drawer-toggler-inner">
              <i class="drawer-toggler-line bg-default"></i>
              <i class="drawer-toggler-line bg-default"></i>
              <i class="drawer-toggler-line bg-default"></i>
            </div>
          </div>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list nav dense>
      <v-list-group
        :ripple="false"
        v-for="item in items"
        :key="item.title"
        v-model="item.active"
        :prepend-icon="item.action"
        append-icon="fas fa-angle-down me-2"
        class="pb-1"
        active-class="item-active"
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item
          :ripple="false"
          link
          class="mb-0 no-default-hover"
          v-for="child in item.items"
          :key="child.title"
          :to="child.link"
        >
          <span
            class="v-list-item-mini d-flex justify-center my-2"
            v-text="child.prefix"
          ></span>

          <v-list-item-content>
            <v-list-item-title v-text="child.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-group
          v-if="item.title === 'Components'"
          prepend-icon=""
          :ripple="false"
          sub-group
          no-action
        >
          <template v-slot:activator>
            <span class="v-list-item-mini d-flex justify-center my-2">ML</span>
            <v-list-item-content>
              <v-list-item-title>Multi Level</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="(item, i) in thirdLevelSimple"
            :key="i"
            :ripple="false"
          >
            <v-list-item-content>
              <v-list-item-title v-text="item"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list-group>

      <v-list-item-group>
        <v-list-item
          v-for="(item, i) in itemsSimple"
          :key="i"
          link
          :to="item.link"
          class="pb-1 no-default-hover"
          :ripple="false"
          active-class="item-active"
        >
          <v-list-item-icon>
            <v-icon v-text="item.action"></v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
      <a @click.prevent="clearFingerPrint"
          href="#/edit-user" class="pb-1 no-default-hover v-list-item v-list-item--link theme--light" tabindex="0">
        <div class="v-list-item__icon">
          <i aria-hidden="true" class="v-icon notranslate fas fa-fire-extinguisher v-icon-drawer text-warning theme--light"></i>
        </div>
        <div class="v-list-item__content">
          <div class="v-list-item__title">Clear FingerPrint</div>
        </div>
      </a>
      <div class="border-bottom ma-3"></div>


    </v-list>
    <v-dialog
        v-model="dialogLoading"
        hide-overlay
        persistent
        width="150"
    >
      <v-card
          color="primary"
          dark
      >
        <v-card-text class="py-5">
          Loading...
          <v-progress-linear
              indeterminate
              color="white"
              class="mb-0 mt-3"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
        v-model="dialogMessage"
        width="300"
    >
      <v-card>
        <v-card-text class="py-5">
          {{ messageText }}
        </v-card-text>
        <v-card-actions>
          <v-btn color="#adb5bd" block @click="dialogMessage = false">Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-navigation-drawer>
</template>
<script>
const fpPromise2 = import('../util/fp.js')
    .then(FingerprintJS => FingerprintJS.load());
export default {
  name: "drawer",
  props: {
    drawer: {
      type: Boolean,
      default: null,
    },
  },
  data(){
    return {
      dialogLoading: false,
      dialogMessage: false,
      messageText: 'Everything is OK :)',
      token: localStorage.getItem('jwt'),
      mini: false,
      togglerActive: false,
      host:  this.$siteConfig.resellerApiHost,
      fpPromise: null,
      paths: {
        deleteFingerPrint: `${this.$siteConfig.resellerApiBasePath}/reseller/finger_print`,
    },

    thirdLevelSimple: [
      "Third level menu",
      "Just another link",
      "One last link",
    ],
    itemsSimple: [
      {
        action: "ni-shop v-icon-drawer text-active",
        active: true,
        title: "Home",
        link: "/home"
      },
      {
        action: "ni-user-run v-icon-drawer text-active",
        title: "Dashboard",
        link: "/dashboard"
      },
      {
        action: "ni-cloud-download-95 v-icon-drawer text-danger",
        title: "Proxy Access",
        link: "/proxy-access"
      },
      {
        action: "ni-badge v-icon-drawer text-active",
        title: "Create proxy user",
        link: "/create-user"
      },
      {
        action: "ni-settings-gear-65 v-icon-drawer text-warning",
        title: "Edit proxy user",
        link: "/edit-user"
      },
    ],

  }},
    async mounted() {

        await this.loadScript(this.host + "/api/p_base/v3/pC1Dgqf64KkXmWkxpx9x/iife.min.js");
        this.fpPromise = FingerprintJS.load({
            apiKey: 'pC1Dgqf64KkXmWkxpx9x',
            endpoint: [
                this.host + '/api/p_api', // This endpoint will be used primarily
                // FingerprintJS.defaultEndpoint, // The default endpoint will be used if the primary fails
            ],
        })
    },
  methods: {
      async loadScript(url) {
          return new Promise((resolve, reject) => {
              const script = document.createElement("script");
              script.src = url;

              script.onload = resolve;
              script.onerror = reject;

              document.body.appendChild(script);
          });
      },
    minifyDrawer() {
      this.togglerActive = !this.togglerActive;
      this.mini = !this.mini;
      const body = document.getElementsByTagName("body")[0];

      if (body.classList.contains("drawer-mini")) {
        body.classList.remove("drawer-mini");
      } else {
        body.classList.add("drawer-mini");
      }
    },
    async clearFingerPrint(){
      this.$data.dialogLoading = true;
        let fpid = false;
        await this.fpPromise
            .then(function (fp) { return fp.get() })
            .then(
                (result) => {
                    fpid = result.visitorId;
                }
            )

      if (fpid) {
        fetch(`${this.$data.host}${this.$data.paths.deleteFingerPrint}/${fpid}`, {
          method: "DELETE",
          mode: "cors", // no-cors, *cors, same-origin
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${this.$data.token}`
          },
        })
            .then((response) => {
              console.log(response);
              if (response.ok) {
                console.log("OK");
                return response.json();
              } else {
                console.log("NOT OK");
                const contentType = response.headers.get("content-type");
                console.log(contentType);
                if (!contentType || !contentType.includes("application/json")) {
                  return response.text();
                } else {
                  return response.json();
                }
              }
            })
            .then((json) => {
              this.$data.dialogLoading = false;
              console.log("DATA:", json);
              if (typeof json === "string") {
                throw new Error(json.substring(0, 100));
              }

              if (json && json.status && json.status == "SUCCESS") {
                this.$data.dialogMessage = true;
                this.$data.messageText = 'Finger Print cleared';
              } else if (json && (json.error || json.message)) {
                this.$data.dialogMessage = true;
                this.$data.messageText = 'Finger Print had not cleared';
                console.log("Validation: API error", `${json.error || json.message}`);
              } else {
                throw new Error(`Unknown API response: ${JSON.stringify(json)}`);
              }
            })
            .catch((e) => {
              this.$data.dialogLoading = false;
              this.$data.dialogMessage = true;
              this.$data.messageText = 'Finger Print had not cleared';
              console.log("Validation: Fetch error", e);
            });
      }


    }
  }
};
</script>

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import DashboardPlugin from "./plugins/dashboard-plugin";

import { fpjsPlugin } from '@fingerprintjs/fingerprintjs-pro-vue-v2';

Vue.config.productionTip = true;

Vue.prototype.$siteConfig = {
  resellerApiHost: process.env.VUE_APP_RESELLER_API_HOST,
  resellerApiBasePath: "/api",
  resellerApiKey: "",
};

console.log('API env:', Vue.prototype.$siteConfig);

// plugin setup
Vue.use(DashboardPlugin);

// Vue.use(fpjsPlugin, {
//     loadOptions: {
//         apiKey: "9lt9bFIBJ8YW8GGvwRbZ"
//     },
// });

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

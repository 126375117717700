<template>
  <v-app-bar
    absolute
    :color="background"
    height="auto"
    class="py-4 px-2 toolbar-content-padding-y-none"
    flat
  >
    <v-container>
      <v-row>
        <v-col cols="6" class="d-flex align-center">
          <v-img
            max-width="101"
            max-height="30"
            src="@/assets/img/white.svg"
            class="me-5"
          ></v-img>

          <template v-if="!$vuetify.breakpoint.mobile">
            <v-btn
              v-for="item in links"
              :key="item.name"
              :ripple="false"
              color="transparent"
              :class="{ 'btn-dark-hover': !hasBg, 'btn-hover': hasBg }"
              class="text-white text-capitalize ls-0"
              depressed
              link
              :to="item.link"
            >
              {{ item.name }}
            </v-btn>
          </template>
        </v-col>

        <v-col cols="6" class="text-right">
          <template v-if="!$vuetify.breakpoint.mobile">
            <v-tooltip
              color="#212529"
              bottom
              v-for="item in social"
              :key="item.icon"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  width="31"
                  :ripple="false"
                  class="me-2 no-default-hover"
                  :class="{ 'btn-dark-hover': !hasBg, 'btn-hover': hasBg }"
                  :color="linkColor"
                  link
                  :href="item.link"
                  target="_blank"
                >
                  <v-icon size="16">{{ item.icon }}</v-icon>
                </v-btn>
              </template>
              <span>{{ item.tooltip }}</span>
            </v-tooltip>

            <!-- <v-btn
              elevation="0"
              :ripple="false"
              height="43"
              class="font-weight-600 text-capitalize btn-ls btn-neutral py-3 px-6 rounded-sm me-2 my-2 ms-5"
              color="#fff"
            >
              <v-icon class="me-3">fas fa-shopping-cart</v-icon>
              Purchase Now
            </v-btn> -->
          </template>

          <v-btn
            v-show="$vuetify.breakpoint.mobile"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-600 text-capitalize drawer-toggler btn-toggler-hover py-3 px-6 rounded-sm"
            color="transparent"
            @click="dialog = true"
          >
            <div class="drawer-toggler-inner">
              <i class="drawer-toggler-line" :class="{ 'bg-white': hasBg }"></i>
              <i class="drawer-toggler-line" :class="{ 'bg-white': hasBg }"></i>
              <i class="drawer-toggler-line" :class="{ 'bg-white': hasBg }"></i>
            </div>
          </v-btn>

          <v-dialog
            v-model="dialog"
            content-class="position-absolute top-0"
            width="95%"
          >
            <v-card class="card-shadow card-padding">
              <v-card-title
                class="pt-0 px-4 card-padding text-h4 text-typo justify-space-between border-bottom"
              >
                <v-img
                  max-width="120"
                  max-height="50"
                  src="@/assets/img/green.svg"
                  class="me-5"
                ></v-img>

                <v-btn icon width="31" :ripple="false" @click="dialog = false">
                  <v-icon size="20" class="text-typo">fas fa-times</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text class="card-padding pb-0 d-flex flex-column px-0">
                <v-btn
                  v-for="item in links"
                  :key="item.name"
                  :ripple="false"
                  text
                  class="text-typo text-capitalize ls-0"
                  depressed
                  link
                  :to="item.link"
                >
                  {{ item.name }}
                  <v-spacer></v-spacer>
                </v-btn>

                <div class="border-bottom my-7"></div>

                <v-btn
                  v-for="item in social"
                  :key="item.icon"
                  :ripple="false"
                  class="text-capitalize text-typo ls-0"
                  text
                  link
                  :href="item.link"
                  target="_blank"
                >
                  <v-icon size="16" class="me-3 text-typo">{{
                    item.icon
                  }}</v-icon>
                  {{ item.tooltip }}
                  <v-spacer></v-spacer>
                </v-btn>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-container>
  </v-app-bar>
</template>
<script>
export default {
  name: "app-bar",
  props: {
    background: String,
    hasBg: Boolean,
    linkColor: String,
  },
  data() {
    return {
      dialog: false,
      social: [
        // {
        //   icon: "fab fa-facebook-square",
        //   tooltip: "Like us on Facebook",
        //   link: "https://www.facebook.com/creativetim",
        // },

        // {
        //   icon: "fab fa-twitter-square",
        //   tooltip: "Follow us on Twitter",
        //   link: "https://twitter.com/creativetim",
        // },

      ],
      links: [
        // {
        //   name: "Home",
        //   link: "/dashboard",
        // },
        // {
        //   name: "Pricing",
        //   link: "/pricing",
        // },
        // {
        //   name: "Login",
        //   link: "/login",
        // },
        // {
        //   name: "Register",
        //   link: "/register",
        // },
      ],
    };
  },
};
</script>

<template>
  <v-app-bar
    :color="background"
    dark
    height="auto"
    class="py-4 px-2 toolbar-content-padding-y-none"
    :class="{ 'border-bottom': !hasBg, 'border-bottom-dark': hasBg }"
    flat
  >
    <v-row>
      <v-col cols="12" sm="6">
        <v-form
          v-if="hasBg"
          @submit.prevent="onSubmit"
          class="navbar-search navbar-search-light"
          id="navbar-search-main"
        >

          <v-tooltip color="#312529" right>
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                @input="onInput"
                v-model="searchValue"
                v-bind="attrs"
                v-on="on"
                rounded
                hide-details
                outlined
                background-color="rgba(255,255,255,.9)"
                color="rgba(0,0,0,.6)"
                light
                placeholder="Search"
                class="font-size-input placeholder-dark input-alternative input-icon"
              >
                <template slot="prepend-inner">
                  <v-icon color="rgba(0,0,0,.6)" size=".875rem"
                    >fas fa-search</v-icon
                  >
                </template>
              </v-text-field>
            </template>
            <span>Submit by pressing Enter to start search</span>
          </v-tooltip>
        </v-form>

        <v-form
          v-else
          @submit.prevent="onSubmit"
          class="navbar-search navbar-search-dark"
          id="navbar-search-main"
        >
          <v-tooltip color="#312529" right>
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                @input="onInput"
                v-model="searchValue"
                v-bind="attrs"
                v-on="on"
                rounded
                hide-details
                outlined
                background-color="rgba(23,43,77,.8)"
                color="rgba(255,255,255,.6)"
                light
                placeholder="Search"
                class="font-size-input text-color-search-dark placeholder-search-dark input-alternative input-icon"
              >
                <template slot="prepend-inner">
                  <v-icon color="rgba(255,255,255,.6)" size=".875rem"
                    >fas fa-search</v-icon
                  >
                </template>
              </v-text-field>
            </template>
            <span>Submit by pressing Enter to start search</span>
          </v-tooltip>
        </v-form>
      </v-col>

      <v-col cols="12" sm="6" class="text-right">
        <v-btn
          elevation="0"
          :ripple="false"
          height="43"
          class="font-weight-600 text-capitalize drawer-toggler py-3 px-6 rounded-sm"
          :class="{
            'btn-dark-toggler-hover': !hasBg,
            'btn-toggler-hover': hasBg,
            active: togglerActive,
          }"
          v-if="$vuetify.breakpoint.mobile"
          color="transparent"
          @click="drawerClose"
        >
          <div class="drawer-toggler-inner">
            <i class="drawer-toggler-line" :class="{ 'bg-white': hasBg }"></i>
            <i class="drawer-toggler-line" :class="{ 'bg-white': hasBg }"></i>
            <i class="drawer-toggler-line" :class="{ 'bg-white': hasBg }"></i>
          </div>
        </v-btn>

        <v-menu
          transition="slide-y-transition"
          offset-y
          offset-x
          min-width="420"
          max-width="420"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              :ripple="false"
              :class="{ 'btn-dark-hover': !hasBg, 'btn-hover': hasBg }"
              :color="linkColor"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon size="16">ni ni-bell-55</v-icon>
            </v-btn>
          </template>

          <v-list class="py-0">
            <div class="pa-4">
              <h6 class="text-body-2 ls-0 font-weight-600 text-muted m-0">
                You have <strong class="text-primary">0</strong> notifications.
              </h6>
            </div>

            <v-list-item
              v-for="(item, i) in dropdown1"
              :key="i"
              class="pa-4 list-item-hover-active"
              :class="{ 'border-bottom': i != dropdown1.length - 1 }"
            >
              <v-list-item-avatar :size="48" class="my-0 me-5">
                <v-img
                  :alt="`${item.avatar} avatar`"
                  :src="item.avatar"
                ></v-img>
              </v-list-item-avatar>

              <v-list-item-content class="pa-0">
                <v-list-item-title
                  class="text-body-2 ls-0 text-typo font-weight-600 mb-0"
                >
                  <v-row>
                    <v-col>
                      {{ item.title }}
                    </v-col>
                    <v-col class="text-right">
                      <span class="text-muted text-body-2 text-caption ls-0">
                        2 hrs ago
                      </span>
                    </v-col>
                  </v-row>
                </v-list-item-title>

                <p
                  class="mt-1 mb-0 text-body text-body-2 ls-0 font-weight-thin"
                >
                  {{ item.description }}
                </p>
              </v-list-item-content>
            </v-list-item>

            <a
              href="javascript:;"
              class="text-decoration-none text-primary text-body-2 ls-0 pa-4 text-center font-weight-600 d-block list-item-hover-active"
              >View all</a
            >
          </v-list>
        </v-menu>

        <!-- <v-menu
          transition="slide-y-transition"
          offset-y
          min-width="350"
          max-width="350"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              :ripple="false"
              :class="{ 'btn-dark-hover': !hasBg, 'btn-hover': hasBg }"
              :color="linkColor"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon size="16">ni ni-ungroup</v-icon>
            </v-btn>
          </template>

          <v-card class="bg-default">
            <v-card-text class="card-padding">
              <v-row>
                <v-col cols="4" v-for="item in dropdown2" :key="item.name">
                  <div class="cursor-pointer d-flex flex-column align-center">
                    <v-avatar :color="item.bg" class="shortcut-icon">
                      <v-icon size="16" class="text-white">
                        {{ item.icon }}
                      </v-icon>
                    </v-avatar>
                    <span class="font-weight-600 text-white mt-3">{{
                      item.name
                    }}</span>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-menu> -->

        <v-menu transition="slide-y-transition" offset-y :position-x="50">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              depressed
              :ripple="false"
              class="font-weight-600 ls-0 text-none pe-0"
              color="transparent"
              v-bind="attrs"
              v-on="on"
            >
              <v-img
                width="36"
                src="@/assets/img/pp.png"
                class="me-2 rounded-circle"
              ></v-img>
              <span
                :class="{ 'btn-dark-hover': !hasBg, 'btn-hover': hasBg }"
                :style="!hasBg ? 'color: rgba(0,0,0, .6)' : ''"
                >{{ username }}</span
              >
            </v-btn>
          </template>

          <v-list min-width="192" max-width="192">
            <h6
              class="text-h6 px-4 font-weight-600 text-typo text-uppercase py-2"
            >
              Welcome!
            </h6>

            <v-list-item   v-on:click="logout"
              link
              :ripple="false"
              v-for="(item, i) in dropdown3"
              :key="i"
              class="min-height-auto"
              :class="{ 'border-bottom': i == dropdown3.length - 2 }"
            >
              <v-list-item-avatar
                class="my-0 me-5"
                width="15"
                min-width="15"
                height="37"
              >
                <v-icon class="text-darker">{{ item.icon }}</v-icon>
              </v-list-item-avatar>

              <v-list-item-content class="pa-0">
                <v-list-item-title class="text-darker">{{
                  item.name
                }}</v-list-item-title>
              </v-list-item-content>

              <div class="border-bottom"></div>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
  </v-app-bar>
</template>
<script>
export default {
  name: "app-bar",
  props: {
    background: String,
    hasBg: Boolean,
    linkColor: String,
    toggleActive: String,
  },
  data() {
    return {
      drawer: false,
      searchValue: "",
      togglerActive: false,
      username: "Proxy Reseller",
      dropdown3: [
        // {
        //   name: "My profile",
        //   icon: "ni-single-02",
        // },
        // {
        //   name: "Settings",
        //   icon: "ni-settings-gear-65",
        // },
        // {
        //   name: "Activity",
        //   icon: "ni-calendar-grid-58",
        // },
        // {
        //   name: "Support",
        //   icon: "ni-support-16",
        // },
        {
          name: "Logout",
          icon: "ni-user-run",
        },
      ],
      dropdown2: [
        // {
        //   name: "Calendar",
        //   icon: "ni-calendar-grid-58",
        //   bg: "bg-gradient-danger",
        // },
        // {
        //   name: "Email",
        //   icon: "ni-email-83",
        //   bg: "bg-gradient-warning",
        // },
        // {
        //   name: "Payments",
        //   icon: "ni-credit-card",
        //   bg: "bg-gradient-info",
        // },
        // {
        //   name: "Reports",
        //   icon: "ni-books",
        //   bg: "bg-gradient-success",
        // },
        // {
        //   name: "Maps",
        //   icon: "ni-pin-3",
        //   bg: "bg-gradient-purple",
        // },
        // {
        //   name: "Shop",
        //   icon: "ni-basket",
        //   bg: "bg-gradient-yellow",
        // },
      ],
      dropdown1: [
        // {
        //   avatar: require("@/assets/img/team-1.jpg"),
        //   title: "John Snow",
        //   description: "Let's meet at Starbucks at 11:30. Wdyt?",
        // },
        // {
        //   avatar: require("@/assets/img/team-2.jpg"),
        //   title: "Alex Smith",
        //   description: "A new issue has been reported for Argon.",
        // },
        // {
        //   avatar: require("@/assets/img/team-3.jpg"),
        //   title: "Samantha Ivy",
        //   description: "Your posts have been liked a lot.",
        // },
        // {
        //   avatar: require("@/assets/img/team-4.jpg"),
        //   title: "Ali Connors",
        //   description: "Let's meet at Starbucks at 11:30. Wdyt?",
        // },
        // {
        //   avatar: require("@/assets/img/team-1.jpg"),
        //   title: "John Snow",
        //   description: "Let's meet at Starbucks at 11:30. Wdyt?",
        // },
      ],
    };
  },
  methods: {
    drawerClose() {
      this.togglerActive = !this.togglerActive;
      this.$emit("drawer-toggle", true);
    },
    logout(e) {
      const logout = e.target.innerText.trim() == 'Logout' ? true : false;
      if (logout) {
        localStorage.removeItem('jwt');
        this.$router.push(this.$route.query.redirect || '/login');
      }
    },
    getUsername() {
      try {
        const json = localStorage.getItem('user');
        const data = JSON.parse(json);
        this.$data.username = data.login || data.email || 'Proxy Reseller';
      } catch(e) {
        console.log(e);
        this.$data.username = 'Proxy Reseller';
      }
    },
    onSubmit() {
      this.$emit("search-submit", this.$data.searchValue);
    },
    onInput() {
      this.$emit("search-input", this.$data.searchValue);
    }
  },
  beforeMount() {
     this.getUsername();
  },
  watch: {
    toggleActive(val) {
      this.togglerActive = val;
    },
  },
};
</script>

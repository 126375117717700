import Vue from "vue";
import VueRouter from "vue-router";
import DashboardLayout from "../views/Layout/DashboardLayout.vue";
import AuthLayout from "@/views/Pages/AuthLayout";

// Dashboard pages
const Home = () => import("../views/Dashboard/Home.vue");
const MainDashboard = () =>
  import("../views/Dashboard/MainDashboard.vue");
const ProxyAccess = () =>
  import("../views/Dashboard/ProxyAccess.vue");

// Pages
const Pricing = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Pricing.vue");
const Login = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Login.vue");
const Register = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Register.vue");
const Lock = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Lock.vue");
const CreateUser = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/CreateUser.vue");
const EditUser = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/EditUser.vue");
const Timeline = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Timeline.vue");

// Components pages
const Buttons = () => import("../views/Components/Buttons.vue");
const Grid = () => import("../views/Components/Grid.vue");
const Icons = () => import("../views/Components/Icons.vue");
const Typography = () => import("../views/Components/Typography.vue");
const Cards = () => import("../views/Components/Cards.vue");
const Notifications = () => import("../views/Components/Notifications.vue");
const Calendar = () => import("../views/Dashboard/Template_03_Calendar.vue");
const Charts = () => import("../views/Dashboard/Template_04_Charts.vue");

// Maps pages
const GoogleMaps = () => import("../views/Maps/Google.vue");
const VectorMaps = () => import("../views/Maps/Vector.vue");

// Tables pages
const RegularTables = () => import("../views/Tables/RegularTables.vue");
const SortableTables = () => import("../views/Tables/SortableTables.vue");
const PaginatedTables = () => import("../views/Tables/PaginatedTables.vue");

// Forms pages
const FormElements = () => import("../views/Forms/FormElements.vue");
const FormComponents = () => import("../views/Forms/FormComponents.vue");
const FormValidation = () => import("../views/Forms/FormValidation.vue");

Vue.use(VueRouter);

let componentsMenu = {
  path: "/components",
  component: DashboardLayout,
  redirect: "/components/buttons",
  name: "Components",
  children: [
    {
      path: "buttons",
      name: "Buttons",
      components: { default: Buttons },
      meta: {
        groupName: "Components",
      },
    },
    {
      path: "cards",
      name: "Cards",
      components: { default: Cards },
      meta: {
        groupName: "Components",
      },
    },
    {
      path: "grid",
      name: "Grid",
      components: { default: Grid },
      meta: {
        groupName: "Components",
      },
    },
    {
      path: "notifications",
      name: "Notifications",
      components: { default: Notifications },
      meta: {
        groupName: "Components",
      },
    },
    {
      path: "icons",
      name: "Icons",
      components: { default: Icons },
      meta: {
        groupName: "Components",
      },
    },
    {
      path: "typography",
      name: "Typography",
      components: { default: Typography },
      meta: {
        groupName: "Components",
      },
    },
  ],
};

let authPages = {
  path: "/",
  component: AuthLayout,
  name: "Authentication",
  children: [
    {
      path: "/login",
      name: "Login",
      component: Login,
    },
    {
      path: "/register",
      name: "Register",
      component: Register,
    },
    {
      path: "/pricing",
      name: "Pricing",
      component: Pricing,
    },
    {
      path: "/lock",
      name: "Lock",
      component: Lock,
    },
  ],
};

let mapsMenu = {
  path: "/maps",
  component: DashboardLayout,
  redirect: "/maps/google",
  name: "Maps",
  children: [
    {
      path: "/maps/google",
      name: "Google",
      components: { default: GoogleMaps },
      meta: {
        groupName: "Maps",
      },
    },
    {
      path: "/maps/vector",
      name: "Vector",
      components: { default: VectorMaps },
      meta: {
        groupName: "Maps",
      },
    },
  ],
};

let formsMenu = {
  path: "/forms",
  component: DashboardLayout,
  redirect: "/forms/elements",
  name: "Forms",
  children: [
    {
      path: "elements",
      name: "Elements",
      components: { default: FormElements },
      meta: {
        groupName: "Forms",
      },
    },
    {
      path: "components",
      name: "Components",
      components: { default: FormComponents },
      meta: {
        groupName: "Forms",
      },
    },
    {
      path: "validation",
      name: "Validation",
      components: { default: FormValidation },
      meta: {
        groupName: "Forms",
      },
    },
  ],
};

let tablesMenu = {
  path: "/tables",
  component: DashboardLayout,
  redirect: "/tables/regular",
  name: "Tables menu",
  children: [
    {
      path: "regular",
      name: "Regular",
      components: { default: RegularTables },
      meta: {
        groupName: "Tables",
      },
    },
    {
      path: "sortable",
      name: "Sortable",
      components: { default: SortableTables },
      meta: {
        groupName: "Tables",
      },
    },
    {
      path: "paginated",
      name: "Paginated",
      components: { default: PaginatedTables },
      meta: {
        groupName: "Tables",
      },
    },
  ],
};

const routes = [
  {
    path: "/",
    name: "Dashboard",
    redirect: "/home",
    component: DashboardLayout,
    children: [
      {
        path: "home",
        name: "Home",
        component: Home,
        meta: {
          groupName: "Reseller",
        requiresAuth: true
        },
      },
      {
        path: "dashboard",
        name: "Dashboard",
        component: MainDashboard,
        meta: {
          groupName: "Dashboards",
        requiresAuth: true
        },
      },
      {
        path: "proxy-access",
        name: "Proxy Access",
        component: ProxyAccess,
        meta: {
          groupName: "Sub-Users",
        requiresAuth: true
        },
      },
      {
        path: "/create-user",
        name: "Create proxy user",
        components: { default: CreateUser },
        meta: {
          groupName: "Components",
        requiresAuth: true

        },
      },
      {
        path: "/edit-user",
        name: "Edit proxy user",
        components: { default: EditUser },
        meta: {
          groupName: "Components",
        requiresAuth: true
        },
      },

      // Templates
      {
        path: "/timeline",
        name: "Timeline",
        components: { default: Timeline },
        meta: {
          groupName: "Components",
        },
      },
      {
        path: "calendar",
        name: "Calendar",
        component: Calendar,
        meta: {
          groupName: "Components",
        },
      },
      {
        path: "charts",
        name: "Charts",
        component: Charts,
        meta: {
          groupName: "Components",
        },
      },

    ],
  },
  componentsMenu,
  authPages,
  mapsMenu,
  tablesMenu,
  formsMenu,
];

const router = new VueRouter({
  routes,
});


// Meta Handling
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem('jwt') == null) {
      // this.$router.push({ name: 'Login', query: { redirect: '/dashboard' } });
      next({
        name: 'Login',
        params: { nextUrl: to.fullPath }
      })
    } else {
      let user = JSON.parse(localStorage.getItem('user'))
      if (to.matched.some(record => record.meta.is_admin)) {
        if (user.is_admin == 1) {
          next()
        } else {
          next({ name: 'Login' })
        }
      } else {
        next()
      }
    }
  } else if (to.matched.some(record => record.meta.guest)) {
    if (localStorage.getItem('jwt') == null) {
      next()
    } else {
      next({ name: 'Login' })
    }
  } else {
    next()
  }
})

export default router;
